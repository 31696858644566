a, a:visited {
    text-decoration: none;
    color: #DADADA;
}
.wrapper {
    position: absolute;
    width: 100%;
    z-index: 20;
    top: 0;
}
.header {
    width: 100%;
    margin: 0 auto;
    padding: 10px 0;
    transition: 0.4s;
    border-bottom: 0;
}
.headerFixed {
    width: 100%;
    padding: 3px 0;
    margin: 0 auto;
    background-color: #030303;
    position: fixed;
    transition: 0.4s;
    border-bottom: 1px solid #e0a933;
}
.header__content {
    display: flex;
    justify-content: center;
    align-items: center;
}
.header__contentLinks {
    color: #FFFFFF;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    display: flex;
    transition: 0.3s;
}
.header__contentLinksListItem a {
    transition: 0.3s
}
.header__contentLinksListItem a:hover {
    transition: 0.3s;
    color: #e0a933;
}
.header__contentLinksList {
    list-style-type: none;
    display: flex;
}
.header__contentLinksListItem {
    margin: 20px;
    transition: 0.3s;
}
.headerContacts > div {
    display: flex;
    margin-top: 5px;
}
.headerContacts > div > p {
    margin-left: 4px;
}
.headerContacts__inst:hover {
    color: #e0a933;
    cursor: pointer;
    transition: 0.3s;
}
.header__burger {
    display: none;
}
.header__burger:hover {
    cursor: pointer;
}
.header__burgerItem {
    width: 40px;
    height: 4px;
    margin: 5px 0;
    background-color: #e0a933;
}
.header__burgerLinks {
    color:#FFFFFF;
    background-color: #e0a933;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: none;
}
@media screen and (min-width: 2400px) {
    .header {
        padding: 30px 0;
    }
    .header__contentLinks {
        font-size: 27px;
        transition: 0.3s;
        margin-left: 30px;
    }
    .header__contentLinksListItem {
        margin: 40px;
        transition: 0.3s;
    }
    .header__contentLogo {
        transform: scale(1.6);
    }
}
@media screen and (min-width: 2100px) and (max-width: 2400px) {
    .header {
        padding: 35px 0;
    }
    .header__contentLogo {
        transform: scale(1.2);
    }
    .header__contentLinks {
        font-size: 21px;
        transition: 0.3s;
    }
    .header__contentLinksListItem {
        margin: 25px;
        transition: 0.3s;
    }
    .header__contentLogo {
        transform: scale(1.3);
    }
}
@media screen and (max-width: 983px) {
    .headerContacts {
        display: none;
    }
}
@media screen and (max-width: 730px) {
    .header__contentLinks {
        font-size: 12px;
        transition: 0.3s;
    }
    .header__contentLinksListItem {
        margin: 13px;
        transition: 0.3s;
    }
}
@media screen and (max-width: 581px) {
    .header__contentLinks {
        display: none;
    }
    .header__content {
        justify-content: space-between;
        width: 90%;
        margin: 3px auto;
    }
    .header__burger {
        display: block;
    }
}
.headerContacts {
    color: #FFFFFF;
    font-family: 'Montserrat';
    font-weight: 300;
    font-size: 13px;
    margin-left: 30px;
}
