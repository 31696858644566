html, body {
  background-color: #030303;
  overflow-x: hidden;
  scroll-behavior: smooth;
  -webkit-scroll-behavior: smooth;
}
body, h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}
* {
  scrollbar-width: auto;
  scrollbar-color: #e0a933 #232323;
}
/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 15px;
}

*::-webkit-scrollbar-track {
  background: #232323;
}

*::-webkit-scrollbar-thumb {
  background-color: #e0a933;
  border-radius: 10px;
  border: 3px solid #232323;
}
